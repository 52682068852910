import React from "react"

export default function ProfileDesc(props) {
  return (
    <div className="profile-desc">
      <h3>{props.name}</h3>
      <p>{props.description}</p>
    </div>
  )
}
