import React from "react"
import ProfileDesc from "./ProfileDesc"
import ProfilePic from "./ProfilePic"
import ProfileName from "./ProfileName"

export default function Profile(props) {
  return (
    <div className="team-member-profile rgba">
      <ProfilePic {...props} />
      <ProfileName {...props} />
      <ProfileDesc {...props} />
    </div>
  )
}
