import React from "react"
import Logo from "../../components/Logo"
import GoBackArrow from "../../components/GoBackArrow"
import Profile from "../../components/Profile"
import Pic1 from "../../../static/pic-profile-member-1.png"
import Pic2 from "../../../static/pic-profile-member-2.png"
import Pic3 from "../../../static/pic-profile-member-3.png"
import Pic4 from "../../../static/pic-profile-member-4.png"
import Name1 from "../../../static/ROBdownarrow.png"
import Name2 from "../../../static/ADAMdownarrow.png"
import Name3 from "../../../static/BENdownarrow.png"
import Name4 from "../../../static/MARCOdownarrow.png"

const link = (
  <a
    className="profile-personal-link"
    href="https://www.instagram.com/smiley.sound/?hl=en"
    target="_blank"
  >
    Instagram
  </a>
)

const main = ({ location }) => {
  return (
    <div className="aboutus-page">
      <GoBackArrow />
      <div className="secondary-pink-block"></div>
      <div className="page-wrapper">
        <h1 className="description-header">ABOUT US</h1>
        <p className="description">
          A crew of music producers and audio engineers with a positive ethos
          making diverse creations. We have been working in the London /
          international music scene for almost 10 years. Whether you've found us
          in a venue, on a tour or in a studio - I'm sure we've all been
          smiling!
        </p>
      </div>
      <h2 className="block-separator" style={{ marginTop: "95px" }}>
        MEET THE TEAM:
      </h2>
      <div className="profile-block">
        <Profile
          name="Ben"
          imageAsset={Pic3}
          nameAsset={Name3}
          description={link}
        />
        <Profile
          name="Rob"
          imageAsset={Pic1}
          nameAsset={Name1}
          description={link}
        />
        <Profile
          name="Marco"
          imageAsset={Pic4}
          nameAsset={Name4}
          description={link}
        />
        <Profile
          name="Adam"
          imageAsset={Pic2}
          nameAsset={Name2}
          description={link}
        />
      </div>
    </div>
  )
}

export default main
