import React from "react"

export default function ProfileName(props) {
  return (
    <div className="profile-name">
      {" "}
      <img src={props.nameAsset} alt="profile name" />
    </div>
  )
}
