import React from "react"

export default function ProfilePic(props) {
  return (
    <div className="profile-pic ">
      {" "}
      <img src={props.imageAsset} alt="profile picture" />
    </div>
  )
}
